html,
body {
	width: 100% !important;
	height: 100% !important;
	padding: 0 !important;
	margin: 0 !important;
}

.modal-title {
	color: #106eea;
}
