// Custom fonts
@import "fonts";

// Include Bootstrap 5
@import "variables";
@import "bootstrap";

// Site global
@import "layout";
@import "forms";

// 3rd party
@import "lib/dropzone";
@import "lib/select2";
@import "lib/aos";


#hero h3 span {
	color: #106eea;
}

ol, ul {
	padding-left: 5px;
}


/**
* Template Name: BizLand - v3.7.0
* Template URL: https://bootstrapmade.com/bizland-bootstrap-business-template/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
	font-family: 'Lato', 'Open Sans', sans-serif;
	color: #444;
}

a {
	color: #106eea;
	text-decoration: none;
}

a:hover {
	color: #3b8af2;
	text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
	font-family: "Roboto", sans-serif;
}

.main-image {
	width: 100%;
	height: auto;
	box-shadow: 0 0 30px rgba(150, 150, 150, 0.33);

	&.noshadow {
		box-shadow: none;
	}
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	overflow: hidden;
	background: #fff;

	&:before {
		content: "";
		position: fixed;
		top: calc(50% - 30px);
		left: calc(50% - 30px);
		border: 6px solid #106eea;
		border-top-color: #e2eefd;
		border-radius: 50%;
		width: 60px;
		height: 60px;
		-webkit-animation: animate-preloader 1s linear infinite;
		animation: animate-preloader 1s linear infinite;
	}
}

@-webkit-keyframes animate-preloader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes animate-preloader {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
	position: fixed;
	visibility: hidden;
	opacity: 0;
	right: 15px;
	bottom: 15px;
	z-index: 996;
	background: #106eea;
	width: 40px;
	height: 40px;
	border-radius: 4px;
	transition: all 0.4s;

	&:hover {
		background: #3284f1;
		color: #fff;
	}

	&.active {
		visibility: visible;
		opacity: 1;
	}

	span {
		font-size: 28px;
		color: #fff;
		line-height: 0;
	}
}

/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
	[data-aos-delay] {
		transition-delay: 0 !important;
	}
}
/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/
#topbar {
	background: #106eea;
	color: #fff;
	font-size: 14px;
	height: 40px;
	padding: 0;
	transition: all 0.5s;

	.contact-info {
		color: #fff;
		font-style: normal;

		a {
			color: #fff;
			line-height: 0;
			transition: 0.3s;
			padding-left: 5px;

			&:hover {
				color: #fff;
				text-decoration: underline;
			}
		}
	}

	.social-links {
		a {
			color: rgba(255, 255, 255, 0.7);
			font-size: 1.13rem;
			line-height: 0;
			margin-left: 20px;
			transition: 0.3s;

			&:hover {
				color: #fff;
			}
		}
	}
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
	background: #fff;
	transition: all 0.5s;
	z-index: 997;
	height: 86px;
	box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);

	&.fixed-top {
		height: 70px;
	}

	.logo {
		font-size: 30px;
		margin: 0;
		padding: 0;
		line-height: 1;
		font-weight: 600;
		letter-spacing: 0.8px;
		font-family: "Poppins", sans-serif;

		a {
			color: #222222;

			span {
				color: #106eea;
			}
		}

		img {
			max-height: 40px;
		}
	}
}

.scrolled-offset {
	margin-top: 70px;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation
*/
.navbar {
	padding: 0;
}
.navbar ul {
	margin: 0;
	padding: 0;
	display: flex;
	list-style: none;
	align-items: center;
}
.navbar li {
	position: relative;
}
.navbar > ul > li {
	white-space: nowrap;
	padding: 10px 0 10px 28px;
}
.navbar a, .navbar a:focus {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 3px;
	font-size: 15px;
	font-weight: 600;
	color: #222222;
	white-space: nowrap;
	transition: 0.3s;
	position: relative;
}
.navbar a i, .navbar a:focus i {
	font-size: 12px;
	line-height: 0;
	margin-left: 5px;
}
.navbar > ul > li > a:before {
	content: "";
	position: absolute;
	width: 100%;
	height: 2px;
	bottom: -6px;
	left: 0;
	background-color: #106eea;
	visibility: hidden;
	width: 0px;
	transition: all 0.3s ease-in-out 0s;
}
.navbar a:hover:before, .navbar li:hover > a:before, .navbar .active:before {
	visibility: visible;
	width: 100%;
}
.navbar a:hover, .navbar .active, .navbar .active:focus, .navbar li:hover > a {
	color: #106eea;
}
.navbar .dropdown ul {
	display: block;
	position: absolute;
	left: 28px;
	top: calc(100% + 30px);
	margin: 0;
	padding: 10px 0;
	z-index: 99;
	opacity: 0;
	visibility: hidden;
	background: #fff;
	box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
	transition: 0.3s;
}
.navbar .dropdown ul li {
	min-width: 200px;
}
.navbar .dropdown ul a {
	padding: 10px 20px;
	font-weight: 400;
}
.navbar .dropdown ul a i {
	font-size: 12px;
}
.navbar .dropdown ul a:hover, .navbar .dropdown ul .active:hover, .navbar .dropdown ul li:hover > a {
	color: #106eea;
}
.navbar .dropdown:hover > ul {
	opacity: 1;
	top: 100%;
	visibility: visible;
}
.navbar .dropdown .dropdown ul {
	top: 0;
	left: calc(100% - 30px);
	visibility: hidden;
}
.navbar .dropdown .dropdown:hover > ul {
	opacity: 1;
	top: 0;
	left: 100%;
	visibility: visible;
}
@media (max-width: 1366px) {
	.navbar .dropdown .dropdown ul {
		left: -90%;
	}
	.navbar .dropdown .dropdown:hover > ul {
		left: -100%;
	}
}

/**
* Mobile Navigation
*/
.mobile-nav-toggle {
	color: #222222;
	font-size: 28px;
	cursor: pointer;
	display: none;
	line-height: 0;
	transition: 0.5s;
}
.mobile-nav-toggle.bi-x {
	color: #fff;
}

@media (max-width: 991px) {
	.mobile-nav-toggle {
		display: block;
	}

	.navbar ul {
		display: none;
	}
}
.navbar-mobile {
	position: fixed;
	overflow: hidden;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background: rgba(9, 9, 9, 0.9);
	transition: 0.3s;
	z-index: 999;
}
.navbar-mobile .mobile-nav-toggle {
	position: absolute;
	top: 15px;
	right: 15px;
}
.navbar-mobile ul {
	display: block;
	position: absolute;
	top: 55px;
	right: 15px;
	bottom: 15px;
	left: 15px;
	padding: 10px 0;
	background-color: #fff;
	overflow-y: auto;
	transition: 0.3s;
}
.navbar-mobile a, .navbar-mobile a:focus {
	padding: 10px 20px;
	font-size: 15px;
	color: #222222;
}
.navbar-mobile > ul > li {
	padding: 0;
}
.navbar-mobile a:hover:before, .navbar-mobile li:hover > a:before, .navbar-mobile .active:before {
	visibility: hidden;
}
.navbar-mobile a:hover, .navbar-mobile .active, .navbar-mobile li:hover > a {
	color: #106eea;
}
.navbar-mobile .getstarted, .navbar-mobile .getstarted:focus {
	margin: 15px;
}
.navbar-mobile .dropdown ul {
	position: static;
	display: none;
	margin: 10px 20px;
	padding: 10px 0;
	z-index: 99;
	opacity: 1;
	visibility: visible;
	background: #fff;
	box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
}
.navbar-mobile .dropdown ul li {
	min-width: 200px;
}
.navbar-mobile .dropdown ul a {
	padding: 10px 20px;
}
.navbar-mobile .dropdown ul a i {
	font-size: 12px;
}
.navbar-mobile .dropdown ul a:hover, .navbar-mobile .dropdown ul .active:hover, .navbar-mobile .dropdown ul li:hover > a {
	color: #106eea;
}
.navbar-mobile .dropdown > .dropdown-active {
	display: block;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
	width: 100%;
	height: 75vh;
	background: url('../images/bg-hero.jpg') top left;
	background-size: cover;
	position: relative;
}
#hero:before {
	content: "";
	background: rgba(255, 255, 255, 0.6);
	position: absolute;
	bottom: 0;
	top: 0;
	left: 0;
	right: 0;
}
#hero .container {
	position: relative;
}
#hero h1 {
	margin: 0;
	font-size: 48px;
	font-weight: 700;
	line-height: 56px;
	color: #222222;
	font-family: "Poppins", sans-serif;
}
#hero h1 span {
	color: #106eea;
}
#hero h2 {
	color: #555555;
	margin: 5px 0 30px 0;
	font-size: 24px;
	font-weight: 400;
}
#hero .btn-get-started {
	font-family: "Roboto", sans-serif;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 14px;
	letter-spacing: 1px;
	display: inline-block;
	padding: 10px 28px;
	border-radius: 4px;
	transition: 0.5s;
	color: #fff;
	background: #106eea;
}
#hero .btn-get-started:hover {
	background: #247cf0;
}
#hero .btn-watch-video {
	font-size: 16px;
	transition: 0.5s;
	margin-left: 25px;
	color: #222222;
	font-weight: 600;
	display: flex;
	align-items: center;
}
#hero .btn-watch-video i {
	color: #106eea;
	font-size: 32px;
	transition: 0.3s;
	line-height: 0;
	margin-right: 8px;
}
#hero .btn-watch-video:hover {
	color: #106eea;
}
#hero .btn-watch-video:hover i {
	color: #3b8af2;
}
@media (min-width: 1024px) {
	#hero {
		background-attachment: fixed;
	}
}
@media (max-width: 768px) {
	#hero {
		height: 100vh;
	}
	#hero h1 {
		font-size: 28px;
		line-height: 36px;
	}
	#hero h2 {
		font-size: 18px;
		line-height: 24px;
		margin-bottom: 30px;
	}
	#hero .btn-get-started, #hero .btn-watch-video {
		font-size: 13px;
	}
}
@media (max-height: 500px) {
	#hero {
		height: 120vh;
	}
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
section {
	padding: 60px 0;
	overflow: hidden;
}

.section-bg {
	background-color: #f6f9fe;
}

.section-title {
	text-align: center;
	padding-bottom: 30px;

	h1 {
		color: #106eea;
		font-size: 1.91rem;
		font-weight: 700;
		margin: 15px 0 0 0;
	}

	h2 {
		font-size: 1.27rem;
		font-weight: 600;
		margin: 15px 0 0 0;
	}

	p {
		font-weight: 600;
		font-size: 1.13rem;
		margin: 15px auto 0 auto;
	}

	@media (min-width: 1024px) {
		p {
			width: 50%;
		}
	}
}

/*--------------------------------------------------------------
# Dropzone upload
--------------------------------------------------------------*/
.upload-zone {
	width: 100%;
	min-height: 64px;
	padding: 1rem;
	margin: 1rem 0;
	border: 1px solid #404040;
	border-radius: 0;

	.dz-image-preview {
		padding-bottom: var(--bs-gutter-x);

		&.dz-error > .dz-image {
			background-color: #f22;

			img {
				opacity: 0.6;
			}
		}

		.dz-image {
			position: relative;
			box-shadow: 0 0 5px #ccc;

			img {
				width:100%;
			}

			.remove-link {
				background-color: #a22;
				color: #fff;
				position: absolute;
				right: 0.4rem;
				top: 0.4rem;
				padding: 2px 8px;
				margin: 0;
				border-radius: 3px;
			}
		}
	}
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about {
	.text-panel {
		padding-left: 3rem;

		p {
			font-size: 15px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		ul {
			list-style: none;
			padding: 0;

			li {
				display: flex;
				align-items: center;
				padding: 0;
				margin: 2.1rem 0;

				p {
					display: flex;
					margin-left: 32px;
				}

				.circle {
					background: #fff;
					font-size: 19px;
					text-align: center;
					line-height: 62px;
					display: flex;
					align-items: center;
					flex-shrink: 0;
					width: 64px;
					height: 64px;
					padding: 0;
					margin: 0;
					border-radius: 30px;
					box-shadow: 0px 6px 15px rgba(16, 110, 234, 0.12);

					.icon {
						color: #106eea;
						font-size: 21px;
						margin: 0 auto;
					}
				}
			}
		}
	}
}


/*--------------------------------------------------------------
# Instructions
--------------------------------------------------------------*/
.instructions {
	p {
		text-align: justify;
	}

	h5 {
		margin: 1.2rem 0 0.55rem 0;
	}

	ol {
		margin: 0 0 0.55rem 1.67rem;

		li {
			padding: 0 0 0.37rem 0;

			.pro-tip {
				color: #106eea;
			}
		}
	}

	img.teaser {
		float: right;
		margin: 0 0 0.9rem 1.7rem;
		max-width: 40%;
		box-shadow: 0 0 30px rgba(150, 150, 150, 0.33);
	}

	.preview {
		font-size: 1.1rem;
		text-align: center;

		img {
			box-shadow: 0 0 30px rgba(150, 150, 150, 0.33);
		}
	}
}

/*--------------------------------------------------------------
# Tools
--------------------------------------------------------------*/
.tools {
	.container {
		max-width: 800px;
		padding: 0;
		margin: 0 auto;

		h2 {
			margin-left: 15%;
			margin-right: 15%;
		}

		p.note {
			color: $danger;
			font-size: 0.79rem;
		}

		.dropzone {
			background-color: #fff;
			text-align: center;
			padding: 0;
			margin: 1rem 0;
			border: 1px solid #aaa;
			border-radius: 0;

			.dz-message span {
				color: #106eea;
			}

			.dz-preview {
				display: block !important;
				height: 100%;
				padding-bottom: 2.7rem;
				margin: 0;
				border: 0 none;

				.dz-image {
					display: none;
				}

				.dz-details {
					position: relative;

					.dz-filename:hover {
						span {
							background: none;
							border: 0 none;
						}
					}
				}

				.dz-remove {
					background-color: $danger;
					color: #fff;
					font-weight: bold;
					text-align: center;
					line-height: 1.85rem;
					display: block;
					width: 100%;
					height: 1.9rem;
					position: absolute;
					left: 0;
					bottom: 0;
					z-index: 550;
					padding: 0;
					margin: 0;
				}
			}
		}
	}
}


/*--------------------------------------------------------------
# Reports
--------------------------------------------------------------*/

.report-content {
	font-size: 0.735rem;
	padding: 0.33rem;
}

/*--------------------------------------------------------------
# Pricing
--------------------------------------------------------------*/
.pricing {
	.box {
		background: #fff;
		text-align: center;
		height: 100%;
		position: relative;
		padding: 20px 20px 84px 20px;
		border-radius: 5px;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
		overflow: hidden;

		h3 {
			background: #f8f8f8;
			color: #777;
			font-size: 16px;
			font-weight: 600;
			padding: 20px 15px;
			margin: -20px -20px 20px -20px;

			&.featured {
				color: #fff;
				background: #106eea;
			}
		}

		h4 {
			font-size: 16px;
			color: #333;
			font-weight: 600;
			font-family: "Poppins", sans-serif;
			margin-bottom: 20px;

			sup {
				font-size: 20px;
				top: -15px;
				left: -3px;
			}

			span {
				color: #bababa;
				font-size: 16px;
				font-weight: 300;
			}
		}

		ul {
			padding: 0;
			list-style: none;
			color: #444444;
			text-align: center;
			line-height: 20px;
			font-size: 14px;

			li {
				padding-bottom: 16px;

				.icon {
					color: #106eea;
					font-size: 18px;
					padding-right: 4px;
				}

				.na {
					color: #ccc;
					text-decoration: line-through;
				}
			}
		}

		p {
			margin-bottom: 20px;
		}

		.btn-wrap {
			background: #f8f8f8;
			text-align: center;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			padding: 20px 15px;
			margin: 20px 0 0 0;

			.btn-buy {
				background: #106eea;
				display: inline-block;
				padding: 8px 35px 10px 35px;
				border-radius: 4px;
				color: #fff;
				transition: none;
				font-size: 14px;
				font-weight: 400;
				font-family: "Roboto", sans-serif;
				font-weight: 600;
				transition: 0.3s;

				&:hover {
					background: #3b8af2;
				}
			}
		}
	}

	.advanced {
		width: 200px;
		position: absolute;
		top: 18px;
		right: -68px;
		transform: rotate(45deg);
		z-index: 1;
		font-size: 14px;
		padding: 1px 0 3px 0;
		background: #106eea;
		color: #fff;
	}
}

.checkout {
	.pp-button-holder {
		text-align: center;
		margin: 1.7rem auto 0.3rem 0;
	}
}

/*--------------------------------------------------------------
# Frequently Asked Questions
--------------------------------------------------------------*/
.faq {
	padding: 60px 0;

	.faq-list {
		padding: 0;
		list-style: none;

		li {
			border-bottom: 1px solid #d4e5fc;
			margin-bottom: 20px;
			padding-bottom: 20px;
		}

		.question {
			display: block;
			position: relative;
			font-family: #106eea;
			font-size: 18px;
			line-height: 24px;
			font-weight: 400;
			padding-left: 25px;
			cursor: pointer;
			color: #0d58ba;
			transition: 0.3s;
		}

		p {
			text-align: justify;
			padding: 10px 0 0 25px;
			margin-bottom: 0;
		}

		.icon-show {
			display: none;
		}

		.collapsed {
			color: black;

			&:hover {
				color: #106eea;
			}

			.icon-show {
				display: inline-block;
				transition: 0.6s;
			}

			.icon-close {
				display: none;
				transition: 0.6s;
			}
		}
	}
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/

.contact .info-box {
	color: #444444;
	text-align: center;
	box-shadow: 0 0 30px rgba(150, 150, 150, 0.33);
	padding: 20px 0 30px 0;
}
.contact .info-box span {
	font-size: 32px;
	color: #106eea;
	border-radius: 50%;
	padding: 8px;
	border: 2px dotted #b3d1fa;
}
.contact .info-box h3 {
	font-size: 20px;
	color: #777777;
	font-weight: 700;
	margin: 10px 0;
}
.contact .info-box p {
	padding: 0;
	line-height: 24px;
	font-size: 14px;
	margin-bottom: 0;
}

/*--------------------------------------------------------------
# Registration
--------------------------------------------------------------*/

.register {
	.form-floating > .form-control {
		margin: 0 0 0.67rem 0;
	}
}

/*--------------------------------------------------------------
# Static pages
--------------------------------------------------------------*/

.static-page {
	p {
		text-align: justify;
	}

	ul {
		padding: 0;
		margin: 0;

		li {
			text-align: justify;
			margin: 0 0 0.69rem 1.7rem;
		}
	}

	ol {
		list-style-type: none;
		counter-reset: item;
		padding: 0;
		margin: 0;

		li {
			text-align: justify;
		}

		& > li {
			display: table;
			counter-increment: item;
			margin-bottom: 0.9rem;

			&:before {
				content: counters(item, ".") ". ";
				display: table-cell;
				padding-right: 0.9rem;
			}
		}

		li ol > li {
			margin: 0 0 0.69rem 0;

			&:before {
				content: counters(item, ".") " ";
			}
		}
	}
}


/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
	background: #e5e7e9;
	color: #444444;
	font-size: 14px;
	padding: 0 0 30px 0;

	.footer-newsletter {
		padding: 50px 0;
		background: #f1f6fe;
		text-align: center;
		font-size: 15px;

		h4 {
			font-size: 24px;
			margin: 0 0 20px 0;
			padding: 0;
			line-height: 1;
			font-weight: 600;
		}

		form {
			margin-top: 30px;
			background: #fff;
			padding: 6px 10px;
			position: relative;
			border-radius: 4px;
			box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
			text-align: left;

			input[type="email"] {
				border: 0;
				padding: 4px 8px;
				width: calc(100% - 100px);
			}

			input[type="submit"] {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				border: 0;
				background: none;
				font-size: 16px;
				padding: 0 20px;
				background: #106eea;
				color: #fff;
				transition: 0.3s;
				border-radius: 0 4px 4px 0;
				box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);

				&:hover {
					background: #0d58ba;
				}
			}
		}
	}

	.footer-top {
		padding: 60px 0 30px 0;

		h4 {
			font-size: 16px;
			font-weight: bold;
			color: #444444;
			position: relative;
			padding-bottom: 12px;
		}

		.footer-contact {
			margin-bottom: 30px;

			h3 {
				font-size: 24px;
				margin: 0 0 15px 0;
				padding: 2px 0 2px 0;
				line-height: 1;
				font-weight: 700;

				span {
					color: #106eea;
				}
			}

			p {
				font-size: 14px;
				line-height: 24px;
				margin-bottom: 0;
				font-family: "Roboto", sans-serif;
				color: #777777;
			}
		}

		.footer-links {
			margin-bottom: 30px;

			ul {
				list-style: none;
				padding: 0;
				margin: 0;

				li {
					padding: 10px 0;
					display: flex;
					align-items: center;

					&:first-child {
						padding-top: 0;
					}
				}

				span {
					color: #106eea;
					font-size: 0.57rem;
					line-height: 1;
					margin-right: 0.7rem;
				}

				a {
					color: #777777;
					transition: 0.3s;
					display: inline-block;
					line-height: 1;

					&:hover {
						text-decoration: none;
						color: #106eea;
					}
				}
			}
		}

		.social-links a {
			font-size: 18px;
			display: inline-block;
			background: #106eea;
			color: #fff;
			line-height: 1;
			padding: 8px 0;
			margin-right: 4px;
			border-radius: 4px;
			text-align: center;
			width: 36px;
			height: 36px;
			transition: 0.3s;

			&:hover {
				background: #3b8af2;
				color: #fff;
				text-decoration: none;
			}
		}
	}
}
