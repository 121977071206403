@font-face {
	font-family: 'Lato';
	font-weight: normal;
	font-style: normal;
	src:
		local('Lato-Light'),
		url('./fonts/Lato-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'Lato';
	font-weight: normal;
	font-style: italic;
	src:
		local('Lato-LightItalic'),
		url('./fonts/Lato-LightItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Lato';
	font-weight: bold;
	font-style: normal;
	src:
		local('Lato-Bold'),
		url('./fonts/Lato-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Lato';
	font-weight: bold;
	font-style: italic;
	src:
		local('Lato-BoldItalic'),
		url('./fonts/Lato-BoldItalic.ttf') format('truetype');
}

@font-face {
	font-family: 'Raleway Black';
	font-weight: normal;
	font-style: normal;
	src:
		local('Raleway-Black'),
		url('./fonts/Raleway-Black.ttf') format('truetype');
}
